<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-row :style="{ height: '33vh', 'min-height': '250px' }" align="center">
      <v-col align="center" cols="12">
        <v-img src="../assets/1lifelogo.png" max-width="300px"> </v-img>
      </v-col>
      <v-col align="center" cols="12" class="mt-n8">
        <span class="text-h3" style="color: #3564ac"> Signup </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <h1>Signups are currently disabled.</h1>
      </v-col>
    </v-row>
    <!-- <v-form ref="form" :disabled="errorMessage.length > 0">
      <v-row no-gutters align="end" justify="center">
        <v-col v-if="!showResult" align="center" :cols="isMobile ? 10 : 4">
          <v-row align="center">
            <v-col
              ><span class="text-h4 white--black">{{
                companyName
              }}</span></v-col
            >
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="First name"
                background-color="white"
                v-model="user.firstName"
                color="msaBlue"
                filled
                depressed
                :style="{ 'border-radius': '5px' }"
                :rules="rules.firstName"
                :error-messages="errors.firstName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                label="Last name"
                background-color="white"
                v-model="user.lastName"
                color="msaBlue"
                filled
                depressed
                :style="{ 'border-radius': '5px' }"
                :rules="rules.lastName"
                :error-messages="errors.lastName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                label="Pavillion"
                background-color="white"
                v-model="user.title"
                color="msaBlue"
                filled
                depressed
                :style="{ 'border-radius': '5px' }"
                :rules="rules.title"
                :error-messages="errors.title"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                label="Email"
                background-color="white"
                v-model="user.email"
                color="msaBlue"
                filled
                depressed
                :style="{ 'border-radius': '5px' }"
                :rules="rules.email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                label="Username"
                background-color="white"
                v-model="user.username"
                color="msaBlue"
                filled
                depressed
                :style="{ 'border-radius': '5px' }"
                :rules="rules.username"
                :error-messages="errors.username"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="errorMessage.length">
            <v-col>
              <span class="red--text">{{ errorMessage }}</span>
            </v-col>
          </v-row>
          <v-row class="mt-n2">
            <v-col>
              <v-btn
                class="green white--text"
                :disabled="errorMessage.length > 0"
                @click="validateForm"
                block
              >
                Sign Up
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else align="center">
          <v-row align="center">
            <v-col
              ><v-alert
                border="top"
                color="white"
                dark
                class="text-subtitle-1 grey--text text--darken-2"
                max-width="650px"
              >
                <p>
                  You have successfully signed up for {{ result.companyName }}.
                </p>
                <p>Your credentials are:</p>
                <p>
                  Username: {{ result.username }}<br />
                  Password:
                  {{ result.password }}
                </p>
                <p>
                  Use this link to access:
                  <a :href="result.url">1Life Software</a>
                </p>
                <p>
                  A copy of your credentials has been sent to your email address
                  {{ user.email }}.
                </p>
              </v-alert></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </v-form> -->
  </v-container>
</template>
<script>
export default {
  name: 'SignUpPage',
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        username: '',
      },
      companyCypher: '',
      company: { id: 0, name: '' },
      rules: {
        email: [(v) => this.validateEmail(v)],
        username: [(v) => this.validateUsername(v)],
        firstName: [(v) => this.validateFirstName(v)],
        lastName: [(v) => this.validateLastName(v)],
        title: [(v) => this.validateTitle(v)],
      },
      errors: {
        email: [],
        username: [],
        firstName: [],
        lastName: [],
        title: [],
        error: [],
      },
      result: {
        companyName: '',
        username: '',
        password: '',
        url: '',
      },
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showResult() {
      return this.result.companyName.length > 0;
    },
    companyName() {
      return this.company.name;
    },
    errorMessage() {
      var result = '';
      if (this.errors.error.length > 0) {
        result = this.errors.error[0];
      }
      return result;
    },
  },
  methods: {
    validateEmail(email) {
      this.errors.email = [];
      var result = this.$helpers.validateEmail(email, true);
      if (result == 'Email is required for this account type.') {
        return 'Email is required.';
      }
      return this.$helpers.validateEmail(email, true);
    },
    validateUsername(username) {
      this.errors.username = [];
      return this.$helpers.validateUsername(username);
    },
    validateFirstName(name) {
      this.errors.firstName = [];
      return this.$helpers.required(name, 'First name');
    },
    validateLastName(name) {
      this.errors.lastName = [];
      return this.$helpers.required(name, 'Last name');
    },
    validateTitle(title) {
      this.errors.lastName = [];
      return this.$helpers.required(title, 'Pavillion');
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.signUp();
      }
    },
    getCompanyInfo() {
      const url = 'public/get-company-info?format=json';
      const params = {
        companyCypher: this.companyCypher,
        disableAutoError: true,
      };
      this.resetErrors();
      this.$axios
        .post(url, params)
        .then((response) => {
          this.company = response.data;
        })
        .catch((error) => {
          this.handleErrors(error.response.data);
        });
    },
    resetErrors() {
      this.errors.email = [];
      this.errors.username = [];
      this.errors.firstName = [];
      this.errors.lastName = [];
      this.errors.title = [];
      this.errors.error = [];
    },
    handleErrors(errors) {
      if (!Array.isArray(errors)) {
        return;
      }
      errors.forEach((error) => {
        this.errors[error.property].push(error.message);
      });
    },
    signUp() {
      const url = 'public/sign-up?format=json';
      const params = {
        companyCypher: this.companyCypher,
        disableAutoError: true,
        user: this.user,
        loaderText: 'Signing up...',
      };
      this.resetErrors();
      this.$axios
        .post(url, params)
        .then((response) => {
          this.result = response.data;
        })
        .catch((error) => {
          this.handleErrors(error.response.data);
        });
    },
  },
  mounted() {
    /*
    Disabled until Folklorama is back on
    OR 
    auto sign-up support is added for other companies.

    this.companyCypher = this.$route.params.companyCypher;
    this.getCompanyInfo();
    */
  },
};
</script>
